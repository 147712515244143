<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            :contentStyle='{ overflow: "visible" }'
            class="report-templates-edit-dialog"
        >
            <template #header>
                <div class="p-mb-0 dialog-title p-px-0">{{ dialogTitle }}</div>
            </template>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
                <label>Название*</label>
                <InputText
                    type="text"
                    v-model="formData.name"
                    placeholder=""
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.formData.name)"
                    :key="error.$uid"
                    :class="v$.formData.name.$invalid && submitted ? 'p-error' : ''"
                >{{ error.$message }}</small>
            </div>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
                <label>Сортировка*</label>
                <InputText
                    type="text"
                    v-model="formData.sort"
                    placeholder=""
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.formData.sort)"
                    :key="error.$uid"
                    :class="v$.formData.sort.$invalid && submitted ? 'p-error' : ''"
                >{{ error.$message }}</small>
            </div>
            <div
                class="p-field-checkbox p-d-flex p-ai-center p-field p-col-12 p-pb-3 p-pt-0 p-mb-0 p-px-0"
            >
                <Checkbox id="binary" v-model="formData.active" :binary="true" />
                <label for="binary" class="p-my-0 p-mr-0">Активный</label>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button
                        class="p-button"
                        type="button"
                        @click="saveFormGroup"
                    >
                        <span class="p-button-label">{{ buttonLabel }}</span>
                    </Button>
                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <DataTable
            :value="formGroups"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped form-templates-list"
            :rows="20"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            :class="{ showFilters: showFilters }"
            :filters="filters"
            dataKey="id"
            selectionMode="single"
            :scrollable='true'
            scroll-height='calc(100vh - 450px)'
            resizable-columns
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-p-0 p-new-btn">
                            <Button @click="openAddDialog" class="p-button">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Группы листов не найдены</template>
            <Column
                header="Наименование"
                sortable
                field="attributes.name"
                header-class="header-name"
                body-class="body-name"
            >
                <template #body="slotProps">
                    <div :title='slotProps.data.attributes.name' class="p-text-nowrap p-text-truncate">{{ slotProps.data.attributes.name }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter p-my-2" placeholder="Поиск" />
                </template>
            </Column>
            <Column
                header="Сортировка"
                sortable
                field="attributes.sort"
                header-class="header-name"
                body-class="body-name"
            >
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['sort']" class="p-column-filter p-my-2" placeholder="Поиск" />
                </template>
            </Column>
            <Column
                header="Статус"
                sortable
                field="attributes.active"
                header-class="header-name"
                body-class="body-name"
            >
                <template #body="slotProps">
                    <span
                        :title='getStatus(slotProps.data.attributes.active).title'
                        :style='`background: ${ getStatus(slotProps.data.attributes.active).bgColor }; padding: 4px; border-radius: 4px;`'
                    >
                        {{ getStatus(slotProps.data.attributes.active).label }}
                    </span>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['active']" class="p-column-filter p-my-2" placeholder="Поиск" />
                </template>
            </Column>
            <Column
                header=""
                header-class="p-text-center"
                body-style="position: relative; width: 52px; text-align: center;"
                header-style="width: 52px;">
                <template #body="slotProps">
                    <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu class="redLastListElement" :ref="`listMenu${ slotProps.data.id }`" :model="listMenuItems" :popup="true" :baseZIndex="10" />
                </template>
                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import {
    getFormById,
    createWorksheetGroup,
    updateWorksheetGroup
} from '@/api/form';
import { FORM_GROUP_DIALOG_MODES, FORM_TEMPLATES_STATUSES } from '@/constants/forms';
import GroupTemplate from '@/models/GroupTemplate';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';

export default {
    name: 'formGroups',

    mixins: [ requestToastHandler ],

    props: {
        itemId: {
            type: String,
            default: ''
        }
    },

    setup: () => ({ v$: useVuelidate() }),

    validations() {
        return {
            formData: {
                name: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' }
                },

                sort: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' }
                },
            }
        }
    },

    data() {
        this.formTemplateStatuses = FORM_TEMPLATES_STATUSES;

        return {
            formData: new GroupTemplate({ active: true }),
            submitted: false,
            currentDialogMode: FORM_GROUP_DIALOG_MODES.CREATE.NAME,
            showDialog: false,
            formGroups: [],
            totalRecords: null,
            showFilters: false,
            filters: {},
            groupId: null,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editGroup();
                    }
                }
            ],
        };
    },

    async created() {
        if (this.itemId) {
            await this.getData();
        }
    },

    computed: {
        ...mapGetters(['rowsPerPageOptions']),

        selectedRowItem() {
            return this.formGroups.find(i => i.id === this.groupId);
        },

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },

        dialogTitle() {
            return FORM_GROUP_DIALOG_MODES[this.currentDialogMode].TITLE
        },

        buttonLabel() {
            return FORM_GROUP_DIALOG_MODES[this.currentDialogMode].BUTTON
        }
    },

    methods: {
        async getData() {
            this.$emit('loadingChange', true);
            try {
                const { included } = await getFormById(this.itemId);
                this.formGroups = included.filter((item) => item.type === 'form-group');
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        getStatus(active) {
            const status = this.formTemplateStatuses.find(item =>  item.value === (active ? 'active' : 'inactive'));

            return status || {};
        },

        toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps;
            if (id !== this.groupId) {
                this.$refs[`listMenu${ this.groupId }`] && this.$refs[`listMenu${ this.groupId }`].hide(event);
            }
            this.groupId = id;
            this.$refs[`listMenu${ id }`].toggle(event);
        },

        openAddDialog() {
            this.formData = new GroupTemplate({ active: true });
            this.submitted = false;
            this.currentDialogMode = FORM_GROUP_DIALOG_MODES.CREATE.NAME;
            this.showDialog = true;
        },

        editGroup() {
            this.currentDialogMode = 'EDIT';
            this.formData = new GroupTemplate(this.selectedRowItem.attributes);
            this.showDialog = true;
        },

        async saveFormGroup() {
            this.submitted = true;

            if (this.v$.$invalid) {
                return;
            }

            const dataToServer = {
                data: {
                    type: 'form-group',
                    id: this.currentDialogMode === 'EDIT' ? this.groupId : null,
                    attributes: {
                        name: this.formData.name,
                        sort: this.formData.sort,
                        active: this.formData.active ? this.formData.active : false
                    },
                    relationships: {}
                },
            }

            let result;
            this.$emit('loadingChange', true);
            try {
                if (this.currentDialogMode === 'CREATE') {
                    result = await createWorksheetGroup(this.itemId, dataToServer);
                } else {
                    result = await updateWorksheetGroup(this.groupId, dataToServer);
                }
            } catch(error) {
                this.$requestError(result.message);
            } finally {
                this.$emit('loadingChange');
            }

            if (result) {
                this.closeDialog();
                await this.getData();
            }
        },

        closeDialog() {
            this.showDialog = false;
            this.formData = new GroupTemplate({ active: true });
            this.submitted = false;
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
