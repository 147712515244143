import { FORM_FIELDS } from '@/constants/forms';

export default function(data = {}) {
    FORM_FIELDS.forEach(field => {
        this[field] = data[field] || ''
    })
    this.distributionInterval = { id: '', type: 'form-distribution-interval' }
    this.active = false
    this.archive = false
}
