<template>
    <div>
        <TreeTable
            :value="organizations"
            :scrollable='true'
            :paginator="true"
            scroll-height='calc(100vh - 450px)'
            class="p-datatable-customers p-datatable-striped organization-list"
            :class="{ showFilters }"
            :rows="20"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            selectionMode="single"
            :filters="filters"
            resizable-columns
            @node-expand='rowExpandHandler'
            filterMode='lenient'
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                        </span>
                            <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                        </div>
                        <div v-if='showSendButton' class="p-p-0 p-new-btn">
                            <SplitButton
                                @click="mainSendButtonHandler"
                                class='custom-split-button'
                                label='Раздать форму'
                                :model='sendButtonItems'
                                :disabled='disabledSend'
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Организации не найдены</template>
            <template #loading> Загрузка списка организаций. Пожалуйста подождите</template>
            <Column
                header="Название"
                header-class='org-header-name table-header'
                body-class='p-text-nowrap p-text-truncate org-body-name table-body'
                :sortable="true"
                field="shortName"
                :expander='true'
            >
                <template #body='slotProps'>
                    <span :title='slotProps.node.data.fullName'>{{ slotProps.node.data.shortName }}</span>
                </template>
                <template #filter v-show='showFilters'>
                    <InputText
                        v-show='showFilters'
                        type="text"
                        v-model="filters['shortName']"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск по названию"
                    />
                </template>
            </Column>
            <Column
                header="Подчинение"
                header-class='org-header-subordinate table-header'
                body-class='p-text-nowrap p-text-truncate org-body-subordinate table-body'
                :sortable="true"
                field="subordinateTo.name"
            >
                <template #body='slotProps'>
                    <span
                        :title='getPropValue(slotProps.node.data, "subordinateTo", "name")'>
                        {{ getPropValue(slotProps.node.data, "subordinateTo", "name") }}
                    </span>
                </template>
                <template #filter v-show='showFilters'>
                    <Dropdown
                        v-show='showFilters'
                        v-model="filters['subordinateTo.name']"
                        :options="subordinations"
                        option-label='name'
                        option-value='name'
                        placeholder="Подчинение"
                        class="p-column-filter p-my-2"
                        filter
                        :showClear="true"
                        data-key='id'
                        @click.stop
                    />
                </template>
            </Column>
            <Column
                header="Расположение"
                header-class='org-header-location table-header'
                body-class='p-text-nowrap p-text-truncate org-body-location table-body'
                :sortable="true"
                field="location.name"
            >
                <template #body='slotProps'>
                    <span :title='getPropValue(slotProps.node.data, "location", "name")'>{{ getPropValue(slotProps.node.data, "location", "name") }}</span>
                </template>
                <template #filter v-show='showFilters'>
                    <Dropdown
                        v-show='showFilters'
                        v-model="filters['location.name']"
                        :options="locations"
                        option-label='name'
                        option-value='name'
                        placeholder="Расположение"
                        class="p-column-filter p-my-2"
                        filter
                        :showClear="true"
                        data-key='id'
                        @click.stop
                    />
                </template>
            </Column>
            <Column
                header="Тип"
                header-class='org-header-type table-header'
                body-class='p-text-nowrap p-text-truncate org-body-type table-body'
                :sortable="true"
                field="type.name"
            >
                <template #body='slotProps'>
                    <span :title='getPropValue(slotProps.node.data, "type", "name")'>{{ getPropValue(slotProps.node.data, "type", "name") }}</span>
                </template>
                <template #filter v-show='showFilters'>
                    <Dropdown
                        v-show='showFilters'
                        v-model="filters['type.name']"
                        :options="types"
                        option-label='name'
                        option-value='name'
                        placeholder="Тип"
                        class="p-column-filter p-my-2"
                        filter
                        :showClear="true"
                        data-key='id'
                        @click.stop
                    />
                </template>
            </Column>
            <Column
                header="Статус"
                header-class='org-header-status table-header'
                body-class='p-text-nowrap p-text-truncate org-body-status table-body'
                :sortable="true"
                field="status"
            >
                <template #body='slotProps'>
                <span
                    :title='getStatus(slotProps).title'
                    :style='`background: ${getStatus(slotProps).bgColor}; padding: 4px; border-radius: 4px;`'
                >
                    {{ getStatus(slotProps).label }}
                </span>
                </template>
                <template #filter v-show='showFilters'>
                    <Dropdown
                        v-show='showFilters'
                        v-model="filters['status']"
                        :options="orgStatuses"
                        placeholder="Статус"
                        class="p-column-filter"
                        option-value='value'
                        option-label='label'
                        :showClear="true"
                        @click.stop
                    >
                        <!--                    <template #option="slotProps">-->
                        <!--                        <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>-->
                        <!--                    </template>-->
                    </Dropdown>
                </template>
            </Column>
            <Column
                header=""
                header-class='p-text-center'
                body-style="position: relative; width: 52px; text-align: center;"
                header-style='width: 52px;'
            >
                <template #header>
                    <Button
                        icon="pi pi-filter"
                        :class="filterClasses"
                        class="p-button-rounded p-button-outlined filter-btn"
                        @click="showFilters = !showFilters"
                    />
                </template>
                <template #body="slotProps">
                    <template
                        v-if='accessibleItems(listMenuItems).length && !slotProps.node.data.__isBranch'
                    >
                        <div
                            class="p-panel-header-icon p-link"
                            @click.stop="toggleRowMenu($event, slotProps)"
                        >
                            <span class="pi pi-ellipsis-h"></span>
                        </div>
                        <Menu
                            class="redLastListElement"
                            :ref="`listMenu${slotProps.node.key}`"
                            :model="accessibleItems(listMenuItems)"
                            :popup="true"
                            :baseZIndex='10'
                            :class='{ disabled: disabledSend }'
                        />
                    </template>
                </template>
                <!--            <template #body="slotProps">-->
                <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click="$router.push(`/organizations/${slotProps.data.id}`)" />-->
                <!--                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeOrganization(slotProps.data.id)" />-->
                <!--            </template>-->
            </Column>
            <template #paginatorLeft>
                <div class='p-col-6'></div>
            </template>
        </TreeTable>
    </div>
</template>

<script>
import { getOrganizations } from '@/api/organization';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { dataForTreeTable } from '@/main/utils/organizations';
import { ORG_STATUSES } from '@/constants/organizations';
import { deleteFormAssignments, getFormAssignments } from '@/api/form/formAssignments';
import { jsonApiListParser } from '@/main/utils/common';
import { getFormById } from '@/api/form';
import { ASSIGNMENT_DISTRIBUTION_MODES, FORM_FIELDS } from '@/constants/forms';
import Form from '@/models/Form';
import { SHOW_SEND_FORM_EVENT, USER_PERMISSIONS_MAP, PAGE_SIZE } from '@/constants/common';

const { ASSIGNMENT, DISTRIBUTION } = ASSIGNMENT_DISTRIBUTION_MODES

const {
    formWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'organizations',
    mixins: [requestToastHandler],
    props: {
        itemId: {
            type: [String, Number],
            required: true
        }
    },
    activated() {
        if (this.dataLoaded) this.$emit('loadingChange')
    },
    data() {
        this.orgStatuses = ORG_STATUSES
        return {
            filters: {},
            showFilters: false,
            dataLoaded: false,
            form: new Form(),
            locations: [],
            subordinations: [],
            assignments: [],
            types: [],

            editItemId: '',
            organizations: [],
            notTreeParsedOrgs: [],
            included: [],
            arrayLength: 5,
            new: [],
            totalRecords: null,

            currentPage: 1,
            // TODO поправить статусы
            statuses: ['активная', 'не активная'],
            orgTypes: ['Головная', 'Филиал'],
            sendButtonItems: [
                {
                    label: 'Назначить',
                    command: () => {
                        this.openDialogHandler({
                            mode: ASSIGNMENT,
                            form: this.form,
                            filters: {},
                            cbAfterSave: () => this.getAllOrganizations(this.currentPage, PAGE_SIZE, true)
                        })
                    },
                    permissionAccess: [formWrite]
                }
            ],
            listMenuItems: [
                {
                    label: 'Раздать форму',
                    icon: 'pi pi-angle-double-down',
                    class: 'send-form',
                    command: () => {
                        this.openDialogHandler({
                            mode: DISTRIBUTION,
                            form: this.form,
                            filters: {},
                            orgs: [this.selectedOrg],
                            cbAfterSave: () => this.getAllOrganizations(this.currentPage, PAGE_SIZE, true)
                        });
                    },
                    permissionAccess: [formWrite]
                },
                {
                    label: 'Удалить назначение',
                    icon: 'pi pi-trash',
                    command: () => {
                        // this.showDeletePopUp(this.currentPeriodId);
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removeAssignment()
                        })
                    },
                    permissionAccess: [formWrite],
                    class: 'remove-row',
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        selectedOrg() {
            return this.notTreeParsedOrgs.find(i => i.id === this.editItemId) || { id: '' }
        },
        disabledSend() {
            return !this.form.active
        },
        showSendButton() {
            return [formWrite].some(p => this.userPermissionsObject[p])
        }
    },
    methods: {
        mainSendButtonHandler() {
            this.openDialogHandler({
                mode: DISTRIBUTION,
                form: this.form,
                cbAfterSave: () => this.getAllOrganizations(this.currentPage, PAGE_SIZE, true)
            })
        },
        getPropValue(data, prop, subProp) {
            return (data && data[prop] && data[prop][subProp])
        },
        async fetchForm() {
            const { data: { attributes: formAttrs, relationships } } = await getFormById(this.itemId)
            FORM_FIELDS.forEach(key => {
                this.form[key] = formAttrs[key] || ''
            })
            Object.keys(relationships).forEach(prop => {
                const propData = relationships[prop].data
                if (propData && !Array.isArray(propData)) {
                    this.form[prop].id = propData.id
                }
            })
            this.form.id = this.itemId
            this.form.active = formAttrs.active
            this.form.archive = formAttrs.archive
        },
        async openDialogHandler(payload) {
            if (!this.form.active) return
            this.$root.$emit(SHOW_SEND_FORM_EVENT, payload)
        },
        rowClicked({ key }) {
            this.editItemId = key
            this.editItem()
        },
        editItem() {
            this.$router.push(`/organizations/edit/${this.editItemId}`);
        },
        getStatus (slotProps) {
            const status = this.orgStatuses.find(item =>  item.value === slotProps.node.data.status)
            return status || {}
        },
        async toggleRowMenu(event, slotProps) {
            const { node: { key } } = slotProps
            // Close previous popup
            if (key !== this.editItemId) {
                this.$refs[`listMenu${this.editItemId}`] && this.$refs[`listMenu${this.editItemId}`].hide(event)
            }
            this.editItemId = key
            const menuEl = this.$refs[`listMenu${key}`]
            // Open new popup
            menuEl.toggle(event)
            await this.$nextTick()
            const { y } = event
            const { $el } = menuEl
            if ($el.style) $el.style.top = `${y}px`
        },
        async rowExpandHandler({ children, data, key }) {
            const filter = {
                parentId: { $eq: key }
            }
            const result = await getOrganizations({ filter })
            children = result.organizations.length ? result.organizations : children
            console.log(children, data);
        },
        async getAllOrganizations() {
            try {
                const orgInclude = 'organization.location,organization.type,organization.parent,organization.subordinateTo,organization.parent.district,organization.parent.location,organization.parent.type,organization.parent.subordinateTo,organization.parent.parent'
                const { data: assignments, included: incAssignments } = await getFormAssignments({
                    formId: this.itemId,
                    include: orgInclude,
                })
                this.assignments = jsonApiListParser(assignments, incAssignments);
                const orgs = (incAssignments || []).filter(i => i.type === 'organization' && !i.relationships.parent?.data)
                const orgsIncluded = (incAssignments || []).filter(i => i.type !== 'organization')
                const locations = (incAssignments || []).filter(i => i.type === 'organization-location')
                const subordinations = (incAssignments || []).filter(i => i.type === 'organization-structure')
                const types = (incAssignments || []).filter(i => i.type === 'organization-type')
                this.locations = jsonApiListParser(locations)
                this.subordinations = jsonApiListParser(subordinations)
                this.types = jsonApiListParser(types)
                this.organizations = dataForTreeTable(orgs, orgsIncluded)
                this.notTreeParsedOrgs = jsonApiListParser(orgs, orgsIncluded)
            } catch (error) {
                console.log(error);
                this.$requestError(error.message);
            }
        },
        async removeAssignment() {
            try {
                this.$emit('loadingChange', true);
                let assignment = this.assignments.find(i => i.organization.id === this.editItemId);
                const data = {
                    id: assignment.id,
                    type: 'form-assignment',
                    relationships: {
                        form: {
                            data: {
                                id: assignment.form.id,
                                type: 'form'
                            }
                        },
                        organization: {
                            data: {
                                id: assignment.organization.id,
                                type: 'organization'
                            }
                        }
                    }
                };
                const response = await deleteFormAssignments(this.itemId, { data: [data] });
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }
                this.organizations = this.organizations.filter((organization) => organization.key !== this.editItemId);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Организация удалена',
                //     life: 2500,
                // });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },
        orgType(organization) {
            let orgType = 'Филиал';

            if (organization.relationships.parent.data === null) orgType = 'Головная';
            // if (organization.id == '66974f5e-f084-5b17-bd70-8e21f81fcee7') orgType = 'Филиал'; //Пример

            return orgType;
        },


        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    },
    async created() {
        if (this.itemId) {
            this.$emit('loadingChange', true);
            await this.getAllOrganizations(this.currentPage, PAGE_SIZE, true);
            await this.fetchForm();
            this.$emit('loadingChange', false);
            this.dataLoaded = true
        }
    },
};
</script>

<style lang="scss" scoped >
::v-deep {
    .p-multiselect {
        height: unset!important;
    }
    .disabled {
        .send-form {
            a {
                opacity: .6;
            }
        }
    }
}
.p-datatable-customers {
    ::v-deep {
        //.p-treetable-wrapper {
        //    max-height: calc(100vh - 450px);
        //    overflow-y: scroll;
        //    position: relative;
        //    padding-top: 50px;
        //}
        //.p-treetable-thead {
        //    position: absolute;
        //    top: 0px;
        //}
        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
                .table-global-search {
                    margin-top: 0!important;
                }
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset!important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset!important;
                            overflow-x: hidden;
                            &:first-child {
                                padding-left: 49px;
                            }
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px!important;
                    &:hover {
                        background: #a5dca8!important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea!important;
                        &:hover {
                            background: #a5dca8!important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }

        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }

        tr {

        }

        //.table-header, .table-body {
        //    font-size: 13px;
        //    padding: 0 0 0 16.2px;
        //    white-space: nowrap!important;
        //}
        .org {
            &-header {
                &-name {
                    //width: calc(21.09375vw + 16.2px)!important;
                }
                &-subordinate {
                    width: calc(11.71875vw + 16.2px);
                }
                &-location {
                    width: calc(9.375vw + 16.2px);
                }
                &-type {
                    width: calc(17.96875vw + 16.2px);
                }
                &-status {
                    width: calc(6.25vw + 16.2px);
                }
            }
            &-body {
                &-name {

                }
                &-subordinate {

                }
                &-location {

                }
                &-type {

                }
                &-status {

                }
            }
        }
    }
}
</style>
