import { render, staticRenderFns } from "./editForm.vue?vue&type=template&id=4a9ab5c8&scoped=true&"
import script from "./editForm.vue?vue&type=script&lang=js&"
export * from "./editForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9ab5c8",
  null
  
)

export default component.exports