<template>
    <div class='form-edit'>
        <Dialog :visible.sync="showCancelDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы вносили данные. Вы уверены, что хотите покинуть страницу без сохранения данных?</span>
            </div>

            <template #footer>
                <Button class="p-button p-button-text p-button-danger" @click="applyLeaveRoute">
                    <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label">Удалить данные и покинуть</span>
                </Button>
                <Button class="p-button p-component p-button-text" type="button" autofocus @click="showCancelDialog = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Вернуться к заполнению</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
        <h5 class="p-d-inline p-pt-0 edit-form-title">{{ formTitle }}</h5>
        <FixedTabView
            class='edit-form__tabs'
            :activeIndex.sync='activeTabIdx'
            @tab-click="lockChangeFormTabs"
        >
            <TabPanel
                v-for='(tab, index) in tabs'
                :key='index'
                :header='tab.label'
                :disabled='tab.disabled'
                :active.sync='activeTabs[index]'
            >
                <component
                    v-if='activeTabs[index]'
                    :is='tab.component'
                    :edit-mode='!!formId'
                    :item-id='formId'
                    @loadingChange='$emit("loadingChange", $event)'
                    @change-data='changedDataHandler'
                />
            </TabPanel>
        </FixedTabView>
    </div>
</template>

<script>
import { FORMS_FORM_TITLES } from '@/constants/forms';
import common from '@/pages/forms/editFormTabs/common';
import formTemplates from '@/pages/forms/editFormTabs/formTemplates';
import formGroups from '@/pages/forms/editFormTabs/formGroups';
import reportTemplates from '@/pages/forms/editFormTabs/reportTemplates';
import organizations from '@/pages/forms/editFormTabs/organizations';
import users from '@/pages/forms/editFormTabs/users';

export default {
    name: 'editForm',
    props: {
        formId: {
            type: [String, Number],
            default: ''
        }
    },
    components: {
        common,
        formTemplates,
        formGroups,
        reportTemplates,
        organizations,
        users
    },
    data() {
        this.editTitle = FORMS_FORM_TITLES.EDIT
        this.createTitle = FORMS_FORM_TITLES.CREATE
        return {
            toFullPath: '',
            changedData: false,
            acceptAction: null,
            showCancelDialog: false,
            dataLoaded: false,
            activeTabIdx: 0,
            activeTabs: [true, false, false, false, false, false ],
            form: this.formId
        }
    },
    computed: {
        itemId() {
            return this.$route.params.formId;
        },
        formTitle() {
            return this.formId ? this.editTitle : this.createTitle
        },
        currentTab() {
            return this.tabs[this.activeTabIdx]
        },
        tabs() {
            return [
                {
                    label: 'Общее',
                    component: 'common'
                },
                {
                    label: 'Шаблоны формы',
                    component: 'formTemplates',
                    disabled: !this.formId
                },
                {
                    label: 'Группы листов',
                    component: 'formGroups',
                    disabled: !this.formId
                },
                {
                    label: 'Шаблоны отчетов',
                    component: 'reportTemplates',
                    disabled: !this.formId
                },
                {
                    label: 'Организации',
                    component: 'organizations',
                    disabled: !this.formId
                },
                {
                    label: 'Пользователи',
                    component: 'users',
                    disabled: !this.formId
                }
            ];
        }
    },
    methods: {
        lockChangeFormTabs(event) {
            this.form = this.itemId;
            if (!this.form && this.changedData) {
                this.$root.$emit('show-leave-dialog', {
                    acceptCb: async () => await this.acceptAction(),
                    beforeClose: () => {
                        this.changedData = false
                        this.acceptAction = null
                    }
                });
            } else {
                this.activeTabIdx = this.tabs.findIndex(item => item.label === event.index.header);
                this.activeTabs = [false, false, false, false];
                this.activeTabs[this.activeTabIdx] = true;
            }
        },
        changedDataHandler({ value, acceptAction }) {
            this.changedData = value
            this.acceptAction = acceptAction
        },
        applyLeaveRoute() {
            this.changedData = false
            this.$router.push(this.toFullPath)
        }
    },
    watch: {
        activeTabs: {
            handler() {
                if (this.changedData) {
                    this.$root.$emit('show-leave-dialog', {
                        acceptCb: async () => await this.acceptAction(),
                        beforeClose: () => {
                            this.changedData = false
                            this.acceptAction = null
                        }
                    })
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.changedData) {
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.acceptAction(),
                beforeClose: next
            })
            next(false)
        } else {
            next()
        }
    }
};
</script>

<style scoped>

</style>
