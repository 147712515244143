<template>
    <div class='edit-form__container'>
        <form class='edit-form p-card'>
            <div class='p-fluid p-formgrid p-grid p-mx-0 form-fields'>
                <div class="p-col-6 left-col p-p-0">
                    <div class="p-field">
                        <label>Название формы*</label>
                        <input-text
                            v-model="v$.formData.name.$model"
                            placeholder="Введите название"
                            :class="{'p-error': v$.formData.name.$invalid && submitted}"
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.name)"
                            :key="error.$uid"
                            class="p-error"
                        >{{ error.$message }}</small>
                        <small
                            v-show="submitted && isUniqueFormName"
                            class="p-error"
                        >Форма с таким наименованием уже существует.</small>
                    </div>
                    <div class="p-field">
                        <label>Код в системе*</label>
                        <input-text
                            v-model="v$.formData.code.$model"
                            :disabled='editMode'
                            placeholder="Введи код в системе"
                            :class="{'p-error': v$.formData.code.$invalid && submitted}"
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.code)"
                            :key="error.$uid"
                            class="p-error"
                        >{{ error.$message }}</small>
                    </div>
                    <div class="p-field">
                        <label>Тип формы*</label>
                        <Dropdown
                            v-model='formData.type'
                            :options='formTypes'
                            :disabled='editMode'
                            :class="{'p-error': v$.formData.type.$invalid && submitted}"
                            option-label='label'
                            option-value='value'
                            placeholder='Выберите из списка'
                            data-key='value'
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.type)"
                            :key="error.$uid"
                            class="p-error"
                        >{{ error.$message }}</small>
                    </div>
                    <div class="p-field">
                        <label>Периодичность*</label>
                        <Dropdown
                            v-model='formData.distributionInterval.id'
                            :disabled='editMode'
                            :options='formPeriods'
                            :class="{'p-error': v$.formData.distributionInterval.id.$invalid && submitted}"
                            filter
                            option-label='name'
                            option-value='id'
                            placeholder='Выберите из списка'
                            data-key='value'
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.distributionInterval.id)"
                            :key="error.$uid"
                            class="p-error"
                        >{{ error.$message }}</small>
                    </div>
                    <div class="p-field">
                        <label>Статус</label>
                        <Dropdown
                            v-model='currentStatusProp'
                            :options='formStatuses'
                            placeholder='Выберите из списка'
                        />
                    </div>
                </div>
            </div>
        </form>
        <div class='edit-form__actions'>
            <Button class="p-button" label="Сохранить" @click="onSubmit"/>
            <Button class="p-button-outlined" label="Отменить" @click='cancelAction()'/>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { FORM_FIELD_NAMES, FORM_FIELDS, FORM_STATUSES_NAMES, FORM_TYPES, FORM_STATUSES } from '@/constants/forms';
import Form from '@/models/Form';
import { getAllFormsPeriods } from '@/api/form/formsPeriods';
import { jsonApiListParser } from '@/main/utils/common';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { createForm, editFormParametrs, getFormById } from '@/api/form';
import { requestToastHandler } from '@/main/mixins';

const {
    NAME,
    CODE,
    TYPE
} = FORM_FIELD_NAMES

export default {
    name: 'common',
    mixins: [requestToastHandler],
    props: {
        itemId: {
            type: [String, Number],
            default: ''
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        })
        return {
            formData: {
                [NAME]: standart(1),
                [TYPE]: {  required: { ...required, $message: 'Поле обязательно к заполнению' } },
                [CODE]: standart(1),
                distributionInterval: {
                    id: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
                }
            }
        }
    },
    data() {
        this.formTypes = Object.values(FORM_TYPES)
        this.formStatuses = FORM_STATUSES
        return {
            isUniqueFormName: false,
            leaveDialog: false,
            submitted: false,
            formData: new Form(),
            formPeriods: [],
            externalCode: '',
            currentStatus: FORM_STATUSES_NAMES.DRAFT
        }
    },
    computed: {
        currentStatusProp: {
            get() {
                const { active, archive } = this.formData;
                const { ARCHIVE, ACTIVE, DRAFT } = FORM_STATUSES_NAMES;
                if (!active && !archive) return DRAFT;
                if (active && !archive) return ACTIVE;
                return ARCHIVE;
            },
            set(val) {
                const { ARCHIVE, ACTIVE, DRAFT } = FORM_STATUSES_NAMES
                switch (val) {
                    case DRAFT:
                        this.formData.active = false
                        this.formData.archive = false
                        break
                    case ACTIVE:
                        this.formData.active = true;
                        this.formData.archive = false;
                        break;
                    case ARCHIVE:
                        this.formData.active = false;
                        this.formData.archive = true;
                }
            }
        }
    },
    async created() {
        this.$emit("loadingChange",true)
        await this.handleEditData()
        await this.getData()
        this.$emit("loadingChange",false)
        this.$emit('change-data', { value: false })
    },
    methods: {
        async handleEditData () {
            if (this.editMode) {
                const { data: { attributes: formAttrs, relationships } } = await getFormById(this.itemId)
                FORM_FIELDS.forEach(key => {
                    this.formData[key] = formAttrs[key] || ''
                })
                Object.keys(relationships).forEach(prop => {
                    const propData = relationships[prop].data
                    if (propData && !Array.isArray(propData)) {
                        this.formData[prop].id = propData.id
                    }
                })
                this.formData.active = formAttrs.active
                this.formData.archive = formAttrs.archive
            } else {
                this.formData.active = true;
                this.formData.archive = false;
            }
        },
        async onSubmit() {
            this.submitted = true;
            this.isUniqueFormName = false;
            if (!this.v$.$invalid) {
                this.$emit('loadingChange', true)
                const data = new SubmitJsonApi(this.formData, 'form')
                try {
                    this.$emit('loadingChange', true);
                    const { data: result } = this.editMode ? await editFormParametrs(this.itemId, data) : await createForm(data);
                    this.v$.$anyDirty = false;
                    this.$emit('change-data', { value: false })
                    if (!this.editMode) {
                        await this.$router.push(`/forms/edit/${ result.id }`);
                    }
                } catch (error) {
                    this.isUniqueFormName = error.message.includes('уже существует');
                    this.$emit('loadingChange');
                } finally {
                    this.$emit('loadingChange');
                }
            }
        },
        cancelAction() {
            window.history.back()
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async getData() {
            try {
                const { allPeriods, included: periodsIncluded } = await getAllFormsPeriods()
                this.formPeriods = jsonApiListParser(allPeriods, periodsIncluded)
            } catch (error) {
                this.$requestError(error.message);
            }
        }
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.$emit('change-data', { value: true, acceptAction: this.onSubmit })
            }
        }
    }
};
</script>

<style scoped lang='scss'>
.form-fields {
    margin-top: 0!important;
}
</style>
