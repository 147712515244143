<template>
    <div>
        <TreeTable
            :value="userList"
            :paginator="true"
            :lazy="true"
            class="p-datatable-customers p-datatable-striped organization-list"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            :page-link-size="5"
            :filters="filters"
            selectionMode="single"
            :scrollable="true"
            scroll-height="calc(100vh - 400px)"
            @filter="selectedFilter"
            @node-expand="onRowExpand"
            @page="onPage"
            resizable-columns
            :contentStyle='{ overflow: "visible" }'
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                </div>
            </template>
            <template #empty>Пользователи не найдены.</template>
            <template #loading>Загружается список пользователей. Пожалуйста, подождите.</template>
            <Column
                header="Пользователь"
                field="user"
                :expander="true"
                header-class="header-name"
                body-class="body-name p-text-nowrap p-text-truncate"
                filter-match-mode="contains"
            />
            <Column
                header="Роль"
                field="role"
                header-class="user-header-role table-header"
                body-class="user-body-role table-body"
            />
            <Column
                v-for="perm in permissions"
                :key="perm"
                :field="`permissions.${ perm }`"
                body-class="form-permission-checkbox"
                header-class="form-permission-header"
            >
                <template #header>
                    {{ formData[perm].label }}
                </template>
                <template #body="slotProps">
                    <Checkbox v-model="slotProps.node.permissions[perm]" binary :disabled="true" />
                </template>
            </Column>
            <template #paginatorLeft />
        </TreeTable>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { getUsersPermissionsOnForm } from '@/api/user';
import { DEFAULT_PAGE_SIZE, USER_FORM_PERMISSIONS_NAMES } from '@/constants/common';
import { fetchFormPermissions, getFormById, getFormGroupPermissions } from '@/api/form';
import FormPermissions from '@/models/FormPermissions';
import { jsonApiListParser } from '@/main/utils/common';

export default {

    name: 'users',

    mixins: [ requestToastHandler ],

    props: {
        itemId: {
            type: [ String ],
            required: true
        }
    },

    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.permissions = USER_FORM_PERMISSIONS_NAMES;

        return {
            formData: new FormPermissions(),
            userList: [],
            groups: {},
            currentPage: 1,
            totalRecords: null,
            showFilters: false,
            filters: {},
            sortField: '',
            userFormGroupPermissions: []
        };
    },

    async mounted() {
        this.$emit('loadingChange');
        try {
            const { included } = await getFormById(this.itemId);
            this.groups = included.filter((item) => item.type === 'form-group');

            let filter = {
                'timestamps.confirmedAt': { $lte: new Date() },
                'timestamps.blockedAt': { $eq: null }
            };

            const { data: { data, meta } } = await getUsersPermissionsOnForm(this.itemId, filter);
            for (const user of data) {
                let permissions = await this.getPermissionsByUserId(user.id);
                this.userList.push({
                    key: user.id,
                    data: {
                        user: `${user.attributes.lastName} ${user.attributes.firstName}`,
                        role: user.relationships.role.data?.id || 'нет роли',
                    },
                    permissions,
                    children: []
                });
            }
            this.totalRecords = meta.pagination.total;

            if (this.groups) {
                this.groups = this.groups.map(group => ({
                    key: group.id,
                    data: {
                        user: group.attributes.name,
                        role: '',
                    }
                }));
                await this.expandAll();
            }
        } catch (error) {
            this.$requestError(error.message);
        } finally {
            this.$emit('loadingChange');
        }
    },

    methods: {
        onPage({ page, rows }) {
            this.pageSize = rows;
            this.currentPage = page + 1;
            this.debouncedFilter();
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {

        },

        async expandAll() {
            for (let node of this.userList) {
                await this.onRowExpand(node);
            }
        },

        async onRowExpand(node) {
            if (node.key) {
                let lazyNode = {...node};

                let groups = this.groups;

                if (groups) {
                    const { data: permissionData, included: permissionIncluded } = await getFormGroupPermissions(node.key, `formId[]=${ this.itemId }`);
                    if (permissionData) {
                        this.userFormGroupPermissions[node.key] = jsonApiListParser(permissionData, permissionIncluded);
                    }

                    groups = groups.map(group => ({
                        ...group,
                        permissions: this.getGroupPermissionsByUserId(node.key, group.key)
                    }));
                }
                lazyNode.children = groups;
                this.userList = this.userList.map(n => {
                    if (n.key === node.key) {
                        n = lazyNode;
                    }
                    return n;
                });
            }
        },

        async getPermissionsByUserId(userId) {
            let result = {};
            const { data: userPermissions } = await fetchFormPermissions(userId, { formId: this.itemId });
            userPermissions?.forEach(p => {
                result[p.attributes.operation] = true;
            });
            return result;
        },

        getGroupPermissionsByUserId(userId, groupId) {
            const result = {};
            this.userFormGroupPermissions[userId].filter(p => p.group.id === groupId).forEach(p => {
                result[p.operation] = true;
            });
            return result;
        },
    },

    computed: {
        ...mapGetters([ 'rowsPerPageOptions' ]),

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
    }
}

</script>

<style lang="scss" scoped>
.organization-list {
    ::v-deep {
        .form-permission-header {
            text-align: center;
            width: 9.4585vw;
            font-weight: 500 !important;
        }
        .form-permission-checkbox {
            text-align: center;
            width: 9.4585vw;
            .p-checkbox {
                align-self: center;
                justify-self: center;
            }
        }
    }
}

.p-datatable-customers {
    ::v-deep {
        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
                .table-active-period{
                    margin-top: 12px;
                    .p-column-filter {
                        margin-right: 16px;
                    }
                }
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset !important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset !important;
                            overflow-x: hidden;
                            &:first-child {
                                padding-left: 49px;
                            }
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px !important;
                    &:hover {
                        background: #a5dca8 !important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea !important;
                        &:hover {
                            background: #a5dca8 !important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }
        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }
        .entry {
            &-header {
                &-org {
                    width: calc(10vw + 16.2px);
                }
                &-period {
                    width: calc(12vw + 16.2px);
                }
                &-status {
                    width: calc(8vw + 16.2px);
                }
            }
        }
    }
}
</style>
