import { FORM_TEMPLATES_FIELDS } from '@/constants/forms';

export default function (data = {}) {
    FORM_TEMPLATES_FIELDS.forEach(field => {
        this[field] = data[field] || ''
    })
    this.form = { id: '', type: 'form' }
    this.worksheets = []
    this.groups = []
    this.active = data.active || false
}
